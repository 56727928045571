<script>
import Layout from "../layouts/main";
import UserServiceApi  from "@/service/api/users"

export default {
  components: { Layout },
  data() {
    return {
      updating:false,
      user: JSON.parse(localStorage.getItem("user")),
      userImage:
        JSON.parse(localStorage.getItem("user")).image ||
        require("@/assets/images/no-user-image.jpeg"),
      userEditForm: {
        name: JSON.parse(localStorage.getItem("user")).name,
        lastName: JSON.parse(localStorage.getItem("user")).lastName,
        image_path:null,

      },
    };
  },
  methods: {
    updateUser() {
      let form = new FormData();
      form.append("user_id",this.user.id)
      form.append("action","edit")
      form.append("name",this.userEditForm.name)
      form.append("lastName",this.userEditForm.lastName)
      if(this.userEditForm.image_path && this.userEditForm.image_path.files[0])
        form.append("image_path",this.userEditForm.image_path.files[0].file)
      this.updating = true;
      UserServiceApi.updateUser(form).then((response)=>{
        if(response.data.success){
            this.ToastMessage({title:"User update",body:"User data updated"})
            this.$emit("userUpdated")
        }
        else{
              this.ToastMessage({title:"User update",body:"User data updation failed"},"danger")
        }
            
      }).catch(()=>{
           this.ToastMessage({title:"User update",body:"User data updation failed"},"danger")
      }).finally(()=>
          this.updating = false
        );
    },
  },
};
</script>

<template>
  <Layout>
    <div class="card">
      <div class="row">
        <div class="col-md-4 col-sm-12 border-right">
          <div
            class="d-flex flex-column align-items-center text-center p-3 py-5"
          >
            <img
              class="rounded-circle mt-5"
              :src="userImage"
              width="90"
              alt="User Image"
            /><span class="font-weight-bold">{{ user.name }}</span
            ><span class="text-black-50">{{ user.email }}</span
            ><span>Admin</span>
          </div>
        </div>
        <div class="col-md-8  col-sm-12 p-5">
         

            <form class="needs-validation" @submit.prevent>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="validationCustom02">First Name</label>
                <input
                  type="text"
                  class="form-control user-edit-input"
                  v-model="userEditForm.name"
                  placeholder="First Name Here!"
                />
                <div class="invalid-feedback">
                  <span>First name is required.</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="validationCustom02">Last Name</label>
                <input
                  type="text"
                  v-model="userEditForm.lastName"
                  class="form-control user-edit-input"
                  placeholder="Last Name Here!"
                />
              </div>
            </div>
          </div>
      
    
          <FormulateInput
            type="image"
            name="headshot"
            v-model="userEditForm.image_path"
            label="Change user profile pic"
            help="Select a png, jpg or gif to upload."
            validation="mime:image/jpeg,image/png,image/gif"
          />
  
          <div class="mt-4 text-center my-4">
            <button
              style="margin-top:50px"
              class="btn btn-warning w-md waves-effect waves-light float-right"
              type="button"
              @click="updateUser()"
            >
             <span v-if="this.updating">Updating...</span> <span v-else>Update</span> 
            </button>
          </div>
        </form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.user-profile {
  height: 580px;
}
.user-profile h5 {
  text-align: center;
  font-size: 16px;
  margin-top: 38px;
  margin-bottom: 28px;
  color: rgb(1, 24, 66);
}
</style>
